import { type MessageSchema } from '@/i18n'
import { useAppStore } from '@/stores/appStore'
import { useEnvStore } from '@/stores/envStore'
import { useUserStore } from '@/stores/useUserStore'
import type { User, UserLoginRequest, UserRegisterRequest } from '@/types/api'
import axios from '@/utils/axios'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { decodeCredential, googleSdkLoaded } from "vue3-google-login"
import { useFetch } from '@/composables/useFetch'

const errors = ref<{ [key: string]: string[] }>({})
const isFetching = ref(false)

export const useAuth = () => {
  const toast = useToast()
  const router = useRouter()
  const userStore = useUserStore()
  const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })


  async function initUser() {
    await userStore.initUser()
  }

  async function login(userForm: UserLoginRequest, rememberMe: boolean, password: string) {
    isFetching.value = true
    userForm.password = password
    userForm.remember = rememberMe

    if (!(await getCSRFCookie())) {
      isFetching.value = false
      return
    }

    await axios
      .post(useEnvStore().apiUrl + '/login', userForm)
      .then(async () => {
        await userStore.initUser()
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: `${t('common.welcome')} ${userStore.user?.first_name} !`,
          life: 3000,
        })
        useAppStore().setLanguage(userStore.user?.language || useEnvStore().defaultLocale)
        router.push({ name: 'home' })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })

    isFetching.value = false
  }

  async function register(
    userForm: UserRegisterRequest,
    password: string,
    passwordConfirmation: string,
    toast: any
  ) {
    isFetching.value = true

    // Patch primevue password component reactivity issue
    userForm.password = password
    userForm.password_confirmation = passwordConfirmation

    const language = useAppStore().language || useEnvStore().defaultLocale;
    userForm.language = language;

    // Sanctum CSRF
    if (!(await getCSRFCookie())) {
      isFetching.value = false
      return
    }

    // Register
    await axios
      .post(useEnvStore().apiUrl + '/register', userForm)
      .then(() => {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('auth.registration.success'),
          life: 3000,
        })
        router.push({ path: '/' })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
        const errorMessage = error.response.data.message ?? t('auth.registration.error.message')
        toast.add({ severity: 'error', summary: t('common.error'), detail: errorMessage, life: 1500, });
      })

    isFetching.value = false
  }

  async function logout() {
    isFetching.value = true
    await axios.post(useEnvStore().apiUrl + '/logout')
    userStore.user = null
    localStorage.removeItem('restaurantInfoStore')
    router.push({ name: 'login' })
    isFetching.value = false
  }

  async function getCSRFCookie() {
    try {
      await axios.get(useEnvStore().apiUrl + '/sanctum/csrf-cookie')
      return true
    } catch (error) {
      console.log('error fetching CSRF cookie: ', error)
      toast.add({
        severity: 'error',
        summary: t('common.error'),
        detail: t('registration.error.message'),
        life: 3000,
      })
      return false
    }
  }

  async function sendResetLink(email: string) {
    isFetching.value = true

    await axios
      .post(useEnvStore().apiUrl + '/forgot-password', { email: email })
      .then(() => {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: `Un lien de récupération a été envoyé à ${email}`,
          life: 3000,
        })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })

    isFetching.value = false
  }

  async function resetPassword(
    email: string,
    password: string,
    password_confirmation: string,
    token: string
  ) {
    isFetching.value = true

    await axios
      .post(useEnvStore().apiUrl + '/reset-password', {
        email: email,
        password: password,
        password_confirmation: password_confirmation,
        token: token,
      })
      .then(() => {
        toast.add({
          severity: 'success',
          summary: t('common.success'),
          detail: t('auth.reset.success'),
          life: 3000,
        })
        router.push({ name: 'login' })
      })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })

    isFetching.value = false
  }

  async function loginWithGoogle(info) {
    console.log("loginWithGoogle", info)
    const userData = decodeCredential(info.credential)
    console.log("userData", userData)
    const language = useAppStore().language || useEnvStore().defaultLocale;
    axios.post(useEnvStore().apiUrl + '/auth/social/google', {
      // On envoie l'id token
      id_token: info.credential,
      language: language as 'fr' | 'en' | 'de' | 'it',
      country: 'CH'
    }).then(async (response) => {
      const newUser = response.data.newUser
      await userStore.initUser()
      toast.add({
        severity: 'success',
        summary: t('common.success'),
        detail: `${t('common.welcome')} ${userStore.user?.first_name} !`,
        life: 3000,
      })
      useAppStore().setLanguage(userStore.user?.language || useEnvStore().defaultLocale)
      if (newUser) router.push({ name: 'validate-restaurant' })
      else router.push({ name: 'home' })
    })
      .catch((error) => {
        if (error.response.data.errors) errors.value = error.response.data.errors
      })
  }

  async function validateStepTwoAuth(
    userForm,
    toast: any
  ) {
    isFetching.value = true
    const url = useEnvStore().apiUrl + '/users/step-two-registration'
    const { fetchData } = useFetch()
    const { data, error } = await fetchData(url, 'POST', userForm)
    userStore.user = data as User
    if (error) {
      if (error.details && error.details.errors) {
        errors.value = error.details.errors
      }
      const errorMessage = (error.details && error.details.message) || t('auth.registration.error.message')
      toast.add({ severity: 'error', summary: t('common.error'), detail: errorMessage, life: 1500 })
    } else {
      toast.add({ severity: 'success', summary: t('common.success'), detail: t('auth.registration.success'), life: 3000 })
      router.push({ path: '/' })
    }
    isFetching.value = false
  }

  function redirectHome() {
    console.log(router)
    router.push({ name: 'home' })
  }

  return {
    login,
    register,
    logout,
    errors,
    isFetching,
    user: userStore.user,
    initUser,
    sendResetLink,
    resetPassword,
    redirectHome,
    loginWithGoogle,
    validateStepTwoAuth
  }
}
