<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import type { MessageSchema } from '@/i18n/index.js'
import InputText from 'primevue/inputtext'
import Textarea from 'primevue/textarea'
import InputNumber from '@/components/BaseComponents/InputNumber.vue'
import RadioButton from 'primevue/radiobutton'
import { markRaw, ref, watchEffect } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { AttributesList, Restaurant } from '@/types/restaurants.js'
import { useProfileRestaurant } from '@/composables/useProfileRestaurant.js'
import { watch } from 'vue'
import { useClipboard, useDebounceFn } from '@vueuse/core'
import TheRestaurantCountries from './TheRestaurantCountries.vue'
import ProfileMenuItem from '../ProfileMenuItem.vue'
import HotIcon from '@/assets/icons/MenuFilters/HotIcon.vue'
import { useRouter } from 'vue-router'
// import SupplierIcon from '@/assets/icons/Profile/SupplierIcon.vue'
import { useEnvStore } from '@/stores/envStore'
import { useUserStore } from '@/stores/useUserStore'
// import CountrySelect from './CountrySelect.vue'

const props = defineProps<{
  restaurant: Restaurant
  attributes: AttributesList
}>()
const emit = defineEmits<{
  (e: 'updateRestaurant', restaurant: Restaurant): void
  (e: 'goToTooling'): void
}>()

const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
const { copy } = useClipboard()
const toast = useToast()

const router = useRouter()

const restaurantName = ref(props.restaurant.name)
// const restaurantWebsiteUrl = ref(props.restaurant.website_url)
const restaurantWebsiteUrl = ref(stripProtocol(props.restaurant.website_url))
const restaurantPhoneNumber = ref(props.restaurant.phone_number)
const restaurantLogoUrl = ref(props.restaurant.logo_url)
const restaurantDescription = ref(props.restaurant.description)
const restaurationType = ref(props.restaurant.attributes.restaurationType[0].id)
const restaurantCoverCount = ref(props.restaurant.cover_count)
const restaurantCooks = ref(props.restaurant.cooks_per_service)
// const selectedCountry = ref(props.restaurant.country_alpha2_code)

const { updateRestaurantProperty, updateRestaurantAttributes } = useProfileRestaurant(
  toast,
  props.restaurant.id
)

async function updateProperty(propertyName: string, value: string | number) {
  const updateResponse = await updateRestaurantProperty(propertyName, value)
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
}

// Create a debounced version of updateProperty
const updatePropertyDebounced = useDebounceFn((property: string, value: string | number) => {
  updateProperty(property, value)
}, 500)

watch(restaurationType, async () => {
  const updateResponse = await updateRestaurantAttributes({
    attributeIds: [restaurationType.value],
    isRestaurationType: true,
  })
  if (updateResponse !== null) emit('updateRestaurant', updateResponse)
})

watchEffect(() => {
  if (restaurantCoverCount.value === null) restaurantCoverCount.value = 1
  if (restaurantCooks.value === null) restaurantCooks.value = 1
})

function stripProtocol(url: string | null | undefined): string {
  if (!url) return ''
  return url.replace(/^(https?:\/\/)/, '')
}

function formatWebsiteUrl(url: string): string {
  if (!url) return ''
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url
  }
  return `https://${url}`
}

async function updateWebsiteUrl() {
  const formattedUrl = formatWebsiteUrl(restaurantWebsiteUrl.value)
  await updateProperty('website_url', formattedUrl)
}

watch(restaurantWebsiteUrl, (newValue) => {
  restaurantWebsiteUrl.value = stripProtocol(newValue)
})

// watch(selectedCountry, async (newValue) => {
//   await updateProperty('country_alpha2_code', newValue)
// })
</script>

<template>
  <div class="flex flex-col gap-6">
    <div>
      <h3 class="mb-2">{{ t('profile.profile.restaurantTab.other.title') }}</h3>
      <ProfileMenuItem :icon="markRaw(HotIcon)" :name="t('profile.profile.restaurantTab.other.tooling')" :index="0"
        @click="emit('goToTooling')" />
      <!-- <ProfileMenuItem
        :icon="markRaw(SupplierIcon)"
        :name="t('profile.profile.restaurantTab.other.mySuppliers')"
        :index="1"
        @click="router.push({ name: 'suppliers' })"
      /> -->
    </div>
    <div>
      <h3>{{ t('profile.profile.restaurantTab.historyAndData.title') }}</h3>
      <p class="text-sm">{{ t('profile.profile.restaurantTab.historyAndData.description') }}</p>
      <h4 class="mt-2">{{ t('profile.profile.restaurantTab.historyAndData.restaurantName') }}</h4>
      <InputText class="w-full" v-model="restaurantName" @input="updatePropertyDebounced('name', restaurantName)" />
      <!-- <h4 class="mt-2">{{ t('profile.profile.restaurantTab.historyAndData.restaurantAdress') }}</h4>
      <InputText class="w-full" /> -->
      <!-- <h4 class="mt-2">{{ t('auth.user.form.label.country') }}</h4>
      <CountrySelect v-model="selectedCountry" /> -->
      <h4 class="mt-2">
        {{ t('profile.profile.restaurantTab.historyAndData.restaurantDescription') }}
      </h4>
      <Textarea class="w-full" autoResize :rows="3" v-model="restaurantDescription"
        @input="updatePropertyDebounced('description', restaurantDescription)" />
      <p class="mt-2">
        {{ t('profile.profile.profileTab.personnalData.gastroId') }} :
        <span>
          <b>{{ restaurant.gastro_id }}</b>
        </span>
        <i class="ml-1 text-xl pi pi-copy" @click="copy(restaurant.gastro_id)" />
      </p>
      <div v-if="useEnvStore().environment == 'local' || useEnvStore().environment !== 'production'">
        <h4 class="mt-2">
        Credits
        </h4>
        {{ useUserStore().user?.restaurants[0].credits_used }} / {{ useUserStore().user?.restaurants[0].total_credits }}
      </div>
      <div>
        <h4 class="mt-2">
          {{ t('profile.profile.restaurantTab.historyAndData.restaurantWebsite') }}
        </h4>
        <div class="flex flex-row items-center gap-2">
          <p>https://</p>
          <InputText class="w-full" v-model="restaurantWebsiteUrl" @blur="updateWebsiteUrl"
            placeholder="www.example.com" />
        </div>
      </div>
      <div>
        <h4 class="mt-2">
          {{ t('profile.profile.restaurantTab.historyAndData.restaurantPhoneNb') }}
        </h4>
        <InputText class="w-full" v-model="restaurantPhoneNumber"
          @blur="updateProperty('phone_number', restaurantPhoneNumber)" />
      </div>
    </div>

    <div class="flex flex-row items-end justify-between">
      <div>
        <h3>{{ t('profile.profile.restaurantTab.servings.title') }}</h3>
        <p class="mr-2 text-sm">{{ t('profile.profile.restaurantTab.servings.description') }}</p>
      </div>
      <InputNumber class="w-24 shrink-0" v-model="restaurantCoverCount" :min="1" @blur="
          () => {
            if (restaurantCoverCount < 1) restaurantCoverCount = 1
            updateProperty('cover_count', restaurantCoverCount > 0 ? restaurantCoverCount : 1)
          }
        " />
    </div>

    <div class="flex flex-row items-end justify-between">
      <div>
        <h3 class="mr-2">{{ t('profile.profile.restaurantTab.cooks.title') }}</h3>
        <p class="mr-2 text-sm">{{ t('profile.profile.restaurantTab.cooks.description') }}</p>
      </div>
      <InputNumber class="w-24 shrink-0" v-model="restaurantCooks" :min="1" @blur="
          () => {
            if (restaurantCooks < 1) restaurantCooks = 1
            updateProperty('cooks_per_service', restaurantCooks > 0 ? restaurantCooks : 1)
          }
        " />
    </div>

    <div>
      <h3>{{ t('profile.profile.restaurantTab.restaurantionType.title') }}</h3>
      <p class="text-sm">{{ t('profile.profile.restaurantTab.restaurantionType.description') }}</p>
      <div class="flex flex-col gap-2 mt-2">
        <div class="flex flex-row items-center" v-for="attribute in attributes.restaurationType" :key="attribute.id">
          <RadioButton v-model="restaurationType" :inputId="'radio-' + attribute.name" :name="attribute.name"
            :value="attribute.id" />
          <label :for="'radio-' + attribute.name" class="ml-2">
            {{ t(`profile.profile.restaurantTab.restaurantionType.${attribute.name}`) }}
          </label>
        </div>
      </div>
    </div>
    <div class="-my-6">
      <TheRestaurantCountries @updateCountries="updateRestaurantAttributes({ attributeIds: [$event] })"
        :attributes="props.attributes.inspirations" :restaurant-attributes="props.restaurant.attributes.inspirations" />
    </div>
  </div>
</template>
