<script setup lang="ts">
import NavBarItem from '@/components/NavBar/NavBarItem.vue'
import NavHomeIcon from '@/assets/icons/NavBar/NavHomeIcon.vue'
import NavSearchIcon from '@/assets/icons/NavBar/NavSearchIcon.vue'
import NavOrdersIcon from '@/assets/icons/NavBar/NavOrdersIcon.vue'
import NavCreateIcon from '@/assets/icons/NavBar/NavCreateIcon.vue'
import NavPlanningIcon from '@/assets/icons/NavBar/NavPlanningIcon.vue'
import { useI18n } from 'vue-i18n'
import { type MessageSchema } from '@/i18n'
import { useEnvStore } from '@/stores/envStore'
import NavMyRecipesIcon from '@/assets/icons/NavBar/NavMyRecipesIcon.vue'
const { t } = useI18n<{ message: MessageSchema }>({ useScope: 'global' })
</script>

<template>
  <nav
    :class="(useEnvStore().environment === 'staging' ? 'staging-mode ' : '') + (useEnvStore().environment === 'local' || useEnvStore().environment === 'development' ? 'dev-mode ' : '') + 'fixed bottom-0 grid max-w-[40rem]  w-full h-16 grid-cols-5 bg-white border-t-2 border-primary-300 text-primary-700 z-20'">
    <NavBarItem to="/">
      <template #icon>
        <NavHomeIcon />
      </template>
      <template #text> {{ t('navBar.home') }} </template>
    </NavBarItem>

    <NavBarItem to="/my-recipes">
      <template #icon>
        <NavMyRecipesIcon />
      </template>
      <template #text> {{ t('navBar.myRecipes') }} </template>
    </NavBarItem>

    <NavBarItem to="/menu">
      <template #icon>
        <NavCreateIcon />
      </template>
      <template #text> {{ t('navBar.menu') }} </template>
    </NavBarItem>

    <NavBarItem to="/orders">
      <template #icon>
        <NavOrdersIcon />
      </template>
      <template #text> {{ t('navBar.orders') }} </template>
    </NavBarItem>

    <NavBarItem to="/planning">
      <template #icon>
        <NavPlanningIcon />
      </template>
      <template #text> {{ t('navBar.planning') }} </template>
    </NavBarItem>
  </nav>
</template>

<style scoped>
.dev-mode {
  background-color: rgb(169 255 175)
}

.staging-mode {
  background-color: rgb(243 178 141)
}
</style>